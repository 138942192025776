import { patrolDeviceModelId, aicamDeviceModelId } from "@/config/account"
import { getDeepKeys } from '@/utils/lib'

export const lprDeviceIds = [ ...aicamDeviceModelId, ...patrolDeviceModelId]
export const patrolCarDeviceIds = [ 2100, 2110 ]

const baseConfigTags = [
  'eventDuration', 
  'eventPostMode', 
  'repostDataStoreLimit', 
  'tagFilter',
]
const baseChannelTags = [
  'acceptDiffNum',
  'lprSizeRatio',
  'isCompleteMode',
  'confirmCount',
  'detectMode',
  'indent',
  'roi',
  'lprFrequency',
]

// patrol-car 會有影像來源 imageSourceType（外接鏡頭、內建鏡頭）
// const checkBaseTags = {
//   100: ['aiModelType'],
//   110: ['aiModelType'],
//   120: ['aiModelType'],
//   130: ['aiModelType'],
//   131: ['aiModelType'],
//   140: ['aiModelType'],
//   2100: ['aiModelType', 'imageSourceType'],
//   2110: ['aiModelType', 'imageSourceType'],
//   2200: ['aiModelType'],
//   2210: ['aiModelType'],
// }
const checkBaseTags = {}
lprDeviceIds.forEach(deviceId => {
  if (patrolCarDeviceIds.includes(deviceId))
    checkBaseTags[deviceId] = ['aiModelType', 'imageSourceType']
  else checkBaseTags[deviceId] = ['aiModelType']
})

// 只有131: AiCam-BFV 有分貝計 需檢查分貝計資料 decibelOffset, noiseCarDecibelThreshold, noiseCarEventDuration
const aiCamBfvTags = ['decibelOffset', 'noiseCarDecibelThreshold', 'noiseCarEventDuration']
const checkConfigTags = {}
// const checkConfigTags = {
//   100: baseConfigTags,
//   110: baseConfigTags,
//   120: baseConfigTags,
//   130: baseConfigTags,
//   131: [...baseConfigTags, ...aiCamBfvTags],
//   140: baseConfigTags,
//   2100: baseConfigTags,
//   2110: baseConfigTags,
//   2200: baseConfigTags,
//   2210: baseConfigTags,
// }
lprDeviceIds.forEach(deviceId => {
  if (deviceId === 131) checkConfigTags[deviceId] = [...baseConfigTags, ...aiCamBfvTags]
  else checkConfigTags[deviceId] = baseConfigTags
})

// AiCam 沒有lprFrequency; AiCam 才有 isEnabled
const aicamChannelTags = baseChannelTags.filter(tag => tag !== 'lprFrequency')
aicamChannelTags.push('isEnabled')
const checkChannelTags = {}
// const checkChannelTags = {
//   100: aicamChannelTags,
//   110: aicamChannelTags,
//   120: aicamChannelTags,
//   130: aicamChannelTags,
//   131: aicamChannelTags,
//   140: aicamChannelTags,
//   2100: baseChannelTags,
//   2110: baseChannelTags,
//   2200: baseChannelTags,
//   2210: baseChannelTags,
// }
aicamDeviceModelId.forEach(deviceId => {
  checkChannelTags[deviceId] = aicamChannelTags
})

patrolDeviceModelId.forEach(deviceId => {
  checkChannelTags[deviceId] = baseChannelTags
})


export const configLpr = {
  imageSourceType: {
    title: '$t_ai_image_source'/*影像來源*/,
    default: 0,
    options: [
      { label: '$t_ai_external_cam'/*外接鏡頭*/, value: 0 },
      { label: '$t_ai_internal_cam'/*內建鏡頭*/, value: 1 },
    ],
    tooltip: '$t_ai_image_source_tip',
  },
  paramSource: {
    title: '$t_ai_param_source'/*參數來源*/,
    default: 1,
    options: [
      { label: '$t_ai_global_config'/*系統預設*/, value: 1 },
      { label: '$t_ai_user_config'/*設備客製*/, value: 0 },
    ],
    tooltip: '$t_ai_param_source_tip',
  },
  aiModelType : {
    title: '$t_ai_model_country'/*適用國家*/,
    default: 'tw',
    options: [
      { label: 'TW', value: 'tw' },
      { label: 'JP', value: 'jp' },
      { label: 'VN', value: 'vn' },
    ],
    tooltip: '$t_ai_model_country_tip'
  },
  // for aiCam only has options: tw, jp
  aiModelType_aicam : {
    title: '$t_ai_model_country'/*適用國家*/,
    default: 'tw',
    options: [
      { label: 'TW', value: 'tw' },
      { label: 'JP', value: 'jp' },
    ],
    tooltip: '$t_ai_model_country_tip'
  },
  eventPostMode: {
    title: '$t_ai_event_post_mode'/*事件上傳模式*/,
    default: {
      car: { tw: 0, jp: 0, vn: 0 },
      moto: { tw: 0, jp: 0, vn: 0 },
    },
    options: [
      { label: '$t_ai_event_match'/*比對符合*/, value: 0 },
      { label: '$t_ai_event_all'/*所有辨識*/, value: 1 },
    ],
    tooltip: '$t_ai_event_post_mode_tip',
  },
  notifyFilterMode: {
    title: '$t_ai_notify_filter_mode'/*事件通報模式*/,
    default: {
      tw: 2,
      jp: 2,
      vn: 2,
    },
    options: [
      { label: '$t_ai_notify_match'/*比對符合*/, value: 2 },
      { label: '$t_ai_notify_all'/*全部*/, value: 3 },
      { label: '$t_ai_notify_not_match'/*比對不符合*/, value: 1 },
      { label: '$t_ai_notify_none'/*不通報*/, value: 0 },
    ],
    tooltip: '$t_ai_notify_filter_mode_tip',
  },
  eventDuration: {
    title: '$t_ai_event_duration'/*重複事件間隔*/,
    default: {
      car: { tw: 210, jp: 210, vn: 210 },
      moto: { tw: 210, jp: 210, vn: 210 },
    },
    min: 1,
    max: 600,
    unit: '$t_sec'/*秒*/,
    tooltip: '$t_ai_event_duration_tip',
  },
  repostDataStoreLimit: {
    title: '$t_ai_repost_data_store_limit'/*補傳事件保留上限*/,
    default: {
      car: { tw: 1000, jp: 1000, vn: 1000 },
      moto: { tw: 1000, jp: 1000, vn: 1000 },
    },
    min: 100,
    max: 100000,
    step: 100,
    unit: '$t_ai_event_unit'/*筆*/,
    tooltip: '$t_ai_repost_data_store_limit_tip',
  },
  /* 分貝計 */
  noiseCarDecibelThreshold: {
    title: '$t_ai_noise_car_decibel_threshold'/*噪音分貝閥值*/,
    default: {
      car: { tw: 80, jp: 80, vn: 80 },
      moto: { tw: 80, jp: 80, vn: 80 },
    },
    min: 0,
    max: 200,
    unit: 'db',
    tooltip: '$t_ai_noise_car_decibel_threshold_tip',
  },
  noiseCarEventDuration: {
    title: '$t_ai_noise_event_duration'/*噪音事件重複間隔*/,
    default: {
      car: { tw: 10, jp: 10, vn: 10 },
      moto: { tw: 10, jp: 10, vn: 10 },
    },
    min: 5,
    max: 60,
    unit: '$t_sec'/*秒*/,
    tooltip: '$t_ai_noise_event_duration_tip',
  },
  decibelOffset: {
    title: '$t_ai_decibel_offset'/*校正值*/,
    default: {
      car: { tw: 0, jp: 0, vn: 0 },
      moto: { tw: 0, jp: 0, vn: 0 },
    },
    min: -15,
    max: 15,
    unit: 'db',
    tooltip: '$t_ai_decibel_offset_tip',
  },

  lprFrequency: {
    title: '$t_ai_lpr_frequency'/*辨識頻率*/,
    default: {
      car: { tw: [ 10, 20 ], jp: [ 5, 20 ], vn: [ 10, 20 ]},
      moto: { tw: [ 30 ], jp: [ 30 ], vn: [ 30 ]},
    },
    min: 5,
    max: 30,
    unit: 'fps',
    tooltip: '$t_ai_lpr_frequency_tip',
  },
  isEnabled: {
    title: '$t_ai_enable'/*啟用*/,
    default: [ 1, 1 ],
    options: [
      { label: '$t_on'/*開啟*/, value: 1, icon: 'checked-green.svg', hintIcon: 'checked-gray.svg' },
      { label: '$t_off'/*關閉*/, value: 0, icon: 'Error.svg', hintIcon: 'Error-gray.svg' },
    ],
    tooltip: '$t_ai_enable_tip',
  },
  aiPerformance: {
    title: '$t_ai_recg_fps_level'/*辨識頻率*/,
    default: 0,
    min: 4,
    max: 0,
    step: -1,
    label: {
      4: '$t_ai_level_1'/*最低*/,
      3: '$t_ai_level_2'/*低*/,
      2: '$t_ai_level_3'/*中*/,
      1: '$t_ai_level_4'/*高*/,
      0: '$t_ai_level_5'/*最高*/,
    },
    tooltip: '$t_ai_lpr_frequency_tip',
  },
  lprSizeRatio: {
    title: '$t_ai_lpr_size_ratio'/*'最小車牌比例*/,
    default: [ 5, 5 ],
    min: 1,
    max: 50,
    unit: '%',
    tooltip: '$t_ai_lpr_size_ratio_tip',
  },
  detectMode: {
    title: '$t_ai_lpr_detect_mode'/*比對模式*/,
    default: [ 1, 1 ],
    options: [
      { label: '$t_ai_precise_detect'/*精準比對*/, value: 1 },
      { label: '$t_ai_quick_detect'/*快速比對*/, value: 0 },
    ],
    tooltip: '$t_ai_detect_mode_tip'/*比對模式的說明*/,
  },
  confirmCount: {
    title: '$t_ai_confirm_count'/*確認計數*/,
    default: [ 1, 1 ],
    min: 1,
    max: 5,
    tooltip: '$t_ai_confirm_count_tip'/*確認計數的說明*/,
  },
  acceptDiffNum: {
    title: '$t_ai_accept_diff_num'/*可接受數字差*/,
    default: [ 1, 1 ],
    min: 0,
    max: 3,
    tooltip: '$t_ai_accept_diff_num_tip'/*可接受數字差的說明*/,
  },
  indent: {
    title: '$t_ai_indent'/*辨識邊界*/,
    default: [ 0.01, 0.01 ],
    min: 0.01,
    max: 0.1,
    step: 0.01,
    tooltip: '$t_ai_indent_tip'/*辨識邊界的說明*/,
  },
  isCompleteMode: {
    title: '$t_ai_is_complete_mode'/*完整模式*/,
    default: [ 0, 0 ],
    options: [
      { label: '$t_on'/*開啟*/, value: 1 },
      { label: '$t_off'/*關閉*/, value: 0 },
    ],
    tooltip: '$t_ai_is_complete_mode_tip'/*完整模式的說明*/,
  },
  tagFilter: {
    default: {
      car: { tw: [], jp: [], vn: [] },
      moto: { tw: [], jp: [], vn: [] },
    },
  },
}

/**
 * 設定預設值：檢查參數是否有缺少，若有缺少則補上 defaultSetting的值，
 * 若無則使用 configLpr 設定的預設值
 * @param {*} setting
 * @param {*} refSetting
 */
export const setLprDefaultValue = (setting, refSetting = {}) => {
  // type => 2100: Patrol-Car(iOS), 2200: Patrol-Moto(iOS)
  // type => 131: AiCam-BFV
  let type = ''
  let checkModelTypes = []
  const channelCount = setting.channel.length

  switch (setting.type) {
    case 2100:
    case 2110:
      type = 'car'
      checkModelTypes = ['lprTw', 'lprJp', 'lprVn']
      break
    case 2200:
    case 2210:
      type = 'moto'
      checkModelTypes = ['lprTw', 'lprJp', 'lprVn']
      break
    case 100:
    case 110:
    case 120:
    case 130:
    case 131:
    case 140:
      // AiCam aiModelType 目前只支援 tw, jp
      checkModelTypes = ['lprTw', 'lprJp'] 

      if (channelCount === 2) type = 'car'
      else if (channelCount === 1) type = 'moto'
      break
  }

  const origKeys = getDeepKeys(setting)

  try {
    // 檢查 aiModelType, imageSourceType(patrol-car only)
    checkBaseTags[setting.type].forEach((tag) => {
      if (setting[tag] === null || setting[tag] === undefined) {
        setting[tag] = refSetting[tag] || configLpr[tag].default
      }
    })

    // 檢查 patrol-car 是否有 deviceConfigInternalCam, channelInternalCam 資料
    // 若有缺少則使用 defaultSetting 的 deviceConfigInternalCam, channelInternalCam 的資料
    // 若defaultSetting 沒有則使用 userSetting 的 deviceConfig, channel 的資料
    if (patrolCarDeviceIds.includes(setting.type)) {
      if (!setting.deviceConfigInternalCam) {
        setting.deviceConfigInternalCam = refSetting.deviceConfigInternalCam ? 
          structuredClone(refSetting.deviceConfigInternalCam) :
          structuredClone(setting.deviceConfig)
      }
      if (!setting.channelInternalCam) {
        setting.channelInternalCam = refSetting.channelInternalCam ? 
          structuredClone(refSetting.channelInternalCam) : 
          structuredClone([setting.channel[0]])
      }
    }

    // 檢查 deviceConfig 中是否有缺少的 lprTw, lprJp, lprVn
    // 若有缺少某個語系，先拿 defaultSetting 裡的
    // 若 defaultSetting 也沒有，則使用自己的 lprTw 或第一個的設定 
    // 若是 patrol-car 還有 deviceConfigInternalCam 需要檢查
    const checkDeviceConfigKeys = patrolCarDeviceIds.includes(setting.type) ?
      ['deviceConfig', 'deviceConfigInternalCam'] : ['deviceConfig']
    checkDeviceConfigKeys.forEach((key) => {
      const modelList = Object.keys(setting[key])
      const assignModel = checkModelTypes.includes('lprTw') ? 'lprTw' : checkModelTypes[0]
      checkModelTypes.forEach((model) => {
        if (!modelList.includes(model)) {
          setting[key][model] = refSetting?.[key]?.[model] ? 
            structuredClone(refSetting[key][model]) : 
            structuredClone(setting[key][assignModel])
        }
      })
    })

    // 檢查 deviceConfig (以及 deviceConfigInternalCam)  lprTw, lprJp, lprVn 中是否有缺少的 tag
    // 若有缺少，先用 defaultSetting 的值，若 defaultSetting 也沒有，則用內建的預設值
    checkDeviceConfigKeys.forEach((key) => {
      const deviceConfig = setting[key]
      checkModelTypes.forEach((model) => {
        const deviceConfigModel = deviceConfig[model]
        checkConfigTags[setting.type].forEach((tag) => {
          if (deviceConfigModel[tag] === undefined || 
              deviceConfigModel[tag] === null || 
              deviceConfigModel[tag] === '') {
            deviceConfigModel[tag] = refSetting?.[key]?.[model]?.[tag] ? 
              refSetting[key][model][tag] : 
              configLpr[tag].default[type][model.slice(3).toLowerCase()]
          }
        })
      })
    })

    // 檢查每個 channel 中是否有缺少的 lprTw, lprJp, lprVn
    // 若有缺少某個語系，先拿 defaultSetting 裡的
    // 若 defaultSetting 也沒有，則使用自己的 lprTw 或第一個的設定 
    // 若是 patrol-car 還有 channelInternalCam 需要檢查
    const checkChannelKeys = patrolCarDeviceIds.includes(setting.type) ? 
      ['channel', 'channelInternalCam'] : ['channel']
    const assignModel = checkModelTypes.includes('lprTw') ? 'lprTw' : checkModelTypes[0]
    checkChannelKeys.forEach((key) => {
      // AiCam 檢查 channel 中是否缺少 aiPerformance
      if (aicamDeviceModelId.includes(setting.type)) {
        setting[key].forEach((channel, index) => {
          if (channel.aiPerformance === undefined || channel.aiPerformance === null) {
            channel.aiPerformance = refSetting?.[key]?.[index]?.aiPerformance ?
              refSetting[key][index].aiPerformance : 
              configLpr.aiPerformance.default
          }
        })
      }
      setting[key].forEach((channel, index) => {
        const modelList = Object.keys(channel)
        checkModelTypes.forEach((model) => {
          if (!modelList.includes(model)) {
            setting[key][index][model] = refSetting?.[key]?.[index]?.[model] ? 
              structuredClone(refSetting[key][index][model]) : 
              structuredClone(setting[key][index][assignModel])
          }
        })
      })
    })

    // 檢查每個 channel (以及 channelInternalCam) 的 lprTw, lprJp, lprVn 中是否有缺少的 tag
    checkChannelKeys.forEach((key) => {
      setting[key].forEach((channel, index) => {
        checkModelTypes.forEach((model) => {
          const channelConfig = channel[model]
          checkChannelTags[setting.type].forEach((tag) => {
            if (channelConfig[tag] === undefined ||
                channelConfig[tag] === null ||
                channelConfig[tag] === '') {
              if (tag === 'lprFrequency') {
                channelConfig[tag] = refSetting?.[key]?.[index]?.[model]?.[tag] ?
                  refSetting[key][index][model][tag] : 
                  configLpr[tag].default[type][model.slice(3).toLowerCase()][index]
              } else {
                channelConfig[tag] = refSetting?.[key]?.[index]?.[model]?.[tag] ?
                  refSetting[key][index][model][tag] : 
                  configLpr[tag].default[index]
              }
            }
          })
        })
      })
    })

    const newKeys = getDeepKeys(setting)
    const missingTags = newKeys.filter(key => !origKeys.includes(key))
    return missingTags

  } catch (e) {
    console.error('setLprDefaultValue error:', e)
  }
}