import { apiGetGroupTree, apiGetGroupList } from '@/api/index.js'
import { apiGetUserList } from '@/api/v4.js'
import { getFilterUserTreeList } from '@/utils/lib.js'

const initialState = () => ({
  groupTree: {},
  groupList: [],
  deviceList: [],
  deviceTreeList: [], // 帶public參數的device tree list
  userList: [],
  userTreeList: [],
  privateDeviceList: [], // private device list (public: 0)
})

const state = initialState()

const mutations = {
  updateDeviceList(state, payload) {
    state.deviceList = payload
  },
  updateGroupTree(state, payload) {
    state.groupTree = payload
  },
  updateGroupList(state, payload) {
    state.groupList = payload
  },
  updateDeviceTreeList(state, payload) {
    state.deviceTreeList = payload
  },
  updateUserList(state, payload) {
    state.userList = payload
  },
  updateUserTreeList(state, payload) {
    state.userTreeList = payload
  },
  updatePrivateDeviceList(state, payload) {
    state.privateDeviceList = payload
  }
}

const actions = {
  async getDeviceList({ commit }, { enabled = null, public: pub = null }) {
    const res = await apiGetUserList({ kind: 'device', enabled, public: pub })
    commit('updateDeviceList', res.data)
  },
  async getGroupTree({ commit }) {
    const res = await apiGetGroupTree()
    commit('updateGroupTree', res.data)
  },
  async getDeviceTreeList({ commit, state }, { enabled = null, public: pub = null }) {
    const resDevices = await apiGetUserList({ kind: 'device', enabled: enabled,  public: pub })
    const resTree = await apiGetGroupTree()
    const resGroupList = await apiGetGroupList()

    commit('updateDeviceList', resDevices.data)
    commit('updateGroupTree', resTree.data)
    commit('updateGroupList', resGroupList.data)

    const treeList = getFilterUserTreeList(state.deviceList, state.groupTree)
    commit('updateDeviceTreeList', treeList)
  },
  async getUserTreeList({ commit, state }, { enabled = null, public: pub = null }) {
    const resUsers = await apiGetUserList({ kind: 'user', enabled, public: pub })
    const resTree = await apiGetGroupTree()
    const resGroupList = await apiGetGroupList()

    commit('updateUserList', resUsers.data)
    commit('updateGroupTree', resTree.data)
    commit('updateGroupList', resGroupList.data)

    const treeList = getFilterUserTreeList(state.userList, state.groupTree)
    commit('updateUserTreeList', treeList)
  },
  async getPrivateDeviceList({ commit }) {
    const resDevices = await apiGetUserList({ kind: 'device', enabled: 1, public: 0 }) // public: 0
    commit('updatePrivateDeviceList', resDevices.data)
  },
  async getAccountTreeData({}, { kind = null, enabled = null, public: pub = null }) {
    const resUsers = await apiGetUserList({ kind, enabled, public: pub })
    const resTree = await apiGetGroupTree()

    const treeList = getFilterUserTreeList(resUsers.data, resTree.data)
    return {
      groupTree: resTree.data,
      accountList: resUsers.data,
      accountTreeList: treeList
    }
  },
}

const getters = {
  getAccountName: (state) => (userId) => {
    return state.deviceList.find(user => user.id === userId)?.info.name
  },
  getUserGroupId: (state) => (userId) => {
    return state.deviceList.find(user => user.id === userId)?.groupId
  },
  getDeviceTitleId: (state) => (userId) => {
    const device = state.deviceList.find(user => user.id === userId)
    return device ? `${device.video.title} (${device.id})` : `(${userId})`
  },
  getDeviceModelId: (state) => (userId) => {
    const device = state.deviceList.find(user => user.id === userId)
    return device ? device.deviceModelId : null
  },
  getUserTitleId: (state) => (userId) => {
    const user = state.userList.find((item) => item.id === userId)
    return user ? `${user.video.title} (${user.id})` : `(${userId})`
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}